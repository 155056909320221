// @ts-nocheck
import {
  Typography,
  Dialog,
  Box,
  Paper,
} from "@material-ui/core";
import React from 'react'

export default function BasicDialogue({show, handleClose,title, Content, Action}) {
    return (
      <>
      <Dialog open={show} onClose={handleClose}>
        <Paper variant="outlined" style={{ width: 450 }}>
         <Box
            p={2}
            width={1}
            borderBottom={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography align="left">{title}</Typography>
          </Box>
          <Box
            p={2}
            width={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
           {Content}
          </Box>
          <Box
            p={2}
            width={1}
            borderTop={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
           {Action}
          </Box>
        </Paper>
      </Dialog>
     </>
    );
}

