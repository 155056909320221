// @ts-nocheck
import React from 'react'
import {
   TextField, Button, Dialog, Paper, Typography, Box
} from '@material-ui/core';
import { useSnackbar } from "notistack";


export default function SharedForm({initialValue, open, onClose}) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={open} onClose={onClose} >
    <Paper variant="outlined" style={{ width: 440}}>
        <Box
          p={2}
          width={1}
          borderBottom={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography align="left">Please copy the License Key</Typography>
        </Box>
    <Box style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'16px'}}>
      <TextField
        fullWidth
        size="small"
        label="License Key"
        variant="outlined"
        value={initialValue}
        disabled
      />
      <Button
        variant="contained"
        color="primary"
        style={{marginLeft:8}}
        onClick={() => {
          enqueueSnackbar('License Key copied', { variant: 'success' });
          navigator.clipboard.writeText(initialValue);
          onClose && onClose();
        }}
      >
        Copy
      </Button>
    </Box>
    </Paper>
  </Dialog>
  )
}
