// @ts-nocheck

import React, { useState } from "react";
import BasicDialogue from "./BasicDialogue";
import { TextField, Grid, Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const EditContractId = ({ id, handler, valueId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [contract, setContract] = useState(valueId || "");

  const disabledButton = (value, prevValue) => {
    if (value === prevValue) return true;
    if (prevValue === null && value === "") return true;
    if (value.length > 100) return true;
    return false;
  };

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <TextField
          name="contract_id"
          size="small"
          fullWidth
          label="Contract ID"
          variant="outlined"
          value={contract}
          onChange={(e) => setContract(e.target.value)}
          error={contract.length > 100}
          helperText={
            contract.length > 100 &&
            "The Contract ID field cannot exceed 100 characters"
          }
          placeholder="Please indicate the ID of contract"
        />
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setContract(valueId || "");
          setOpenModal(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handler(id, contract)}
        disabled={disabledButton(contract, valueId)}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={openModal}
        handleClose={() => setOpenModal(false)}
        title="Edit Contract ID"
        Content={Content}
        Action={Action}
      />
      <IconButton color="primary" onClick={() => setOpenModal(!openModal)}>
        <EditIcon />
      </IconButton>
    </>
  );
};

export default EditContractId;
