// @ts-nocheck
import React, { useMemo, Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  TableFooter,
  Typography,
} from "@material-ui/core";
import { Box, CircularProgress } from "@material-ui/core";

export const DataGrid = ({
  isLoading,
  gridColumns = [],
  gridRows = [],
  pagination,
  onSizeChange,
  onPageChange,
  handlerEnableChange,
  data,
  handlerSort,
  handlerEditContractId,
}) => {
  const perPageOptions = useMemo(() => {
    if (!onSizeChange && pagination?.size) return [pagination.size];
    const pageOptions = [10, 30, 50, 100];
    return pagination.amount && pageOptions;
    // const pageOptions = [10, 30, 50];
    // return pagination.amount
    //   ? [...pageOptions, { label: "All", value: pagination.amount }]
    //   : pageOptions;
  }, [onSizeChange, pagination]);

  const sizeChangeHandler = ({ target: { value } }) => {
    onSizeChange(0, value);
  };

  const pageChangeHandler = (_, page) => {
    onPageChange(page);
  };

  return !isLoading ? (
    <TableContainer>
      {data ? (
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {gridColumns.map(({ name, alignPosition, icon }) => {
                return (
                  <TableCell
                    align={alignPosition}
                    key={name}
                    component="th"
                    scope="row"
                  >
                    {icon ? (
                      <Fragment key={name}>{icon(name, handlerSort)}</Fragment>
                    ) : (
                      <Typography>{name}</Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {gridRows.map((rowData, gridRowIndex) => (
              <TableRow
                key={
                  pagination
                    ? `${pagination.size}-${pagination.offset}-${gridRowIndex}`
                    : gridRowIndex
                }
                style={{
                  opacity: rowData.is_enabled || rowData.active ? "1" : ".33",
                }}
              >
                {gridColumns.map(({ CellRenderer, name }) => {
                  const value = rowData;
                  if (CellRenderer && name === "Action")
                    return (
                      <Fragment key={`${name}-${gridRowIndex}`}>
                        {CellRenderer(
                          value,
                          gridRows[gridRowIndex],
                          handlerEnableChange
                        )}
                      </Fragment>
                    );
                  if (CellRenderer && name === "Contract ID")
                    return (
                      <Fragment key={`${name}-${gridRowIndex}`}>
                        {CellRenderer(value, handlerEditContractId)}
                      </Fragment>
                    );
                  else
                    return (
                      <Fragment key={`${name}-${gridRowIndex}`}>
                        {CellRenderer(value, gridRows[gridRowIndex])}
                      </Fragment>
                    );
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {pagination && (
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={perPageOptions}
                  onRowsPerPageChange={sizeChangeHandler}
                  count={pagination.amount}
                  rowsPerPage={
                    perPageOptions.includes(pagination.size)
                      ? pagination.size
                      : pagination.amount
                  }
                  page={pagination.offset / pagination.size}
                  onPageChange={pageChangeHandler}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      ) : (
        <Box
          p={2}
          width={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="left" variant="h2">
            No Data Found
          </Typography>
        </Box>
      )}
    </TableContainer>
  ) : (
    <Box
      p={2}
      width={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size="200px" />
    </Box>
  );
};
