// @ts-nocheck
import React from "react";
import { Tooltip, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

export default function CustumMenus({ element, handlerEnableChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="More">
        <IconButton color="primary" onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        {element.active ? (
          <MenuItem
            onClick={() => {
              handlerEnableChange(element.id, element.active);
              handleClose();
            }}
          >
            {element.active ? "Disable" : "Enable"}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handlerEnableChange(element.id, element.is_enabled);
              handleClose();
            }}
          >
            {element.is_enabled ? "Disable" : "Enable"}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
